<ih-basic-dialog [scrollable]="true">
  <ng-container ih-header>Attendees</ng-container>
  <ng-container ih-content>
    @if (loading()) {
      <div class="loading-container">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    } @else {
      @if (attendees()!.length === 0) {
        <div class="no-attendees">You have no attendees yet</div>
      } @else {
        <mat-accordion>
          @for (attendee of attendees(); track attendee.event_user_id) {
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="attendee-name">{{ attendee.first_name }}&nbsp;{{ attendee.last_name }}</div>
                </mat-panel-title>
                <mat-panel-description class="attendee-panel-description">
                  <div class="attendee-purchase-date">{{ attendee.created_at | date: 'medium' }}</div>
                  <span class="fx-flex"></span>
                  @if (attendee.refunded) {
                    <mat-icon svgIcon="cash-off" matTooltip="Refunded"></mat-icon>
                  }
                  @if (attendee.hasComment) {
                    <mat-icon
                      class="attendee-panel-description-comment"
                      svgIcon="comment-outline"
                      matTooltip="Commented"
                    ></mat-icon>
                  }
                </mat-panel-description>
              </mat-expansion-panel-header>
              <!-- Order details-->
              <div class="attendee-order-details">
                <div class="attendee-order-details-title">Order details</div>

                <div class="attendee-order-details-row fx-layout-row">
                  <div class="attendee-order-details-label">Name:</div>
                  <div class="attendee-order-details-value">
                    {{ attendee.first_name + ' ' + attendee.last_name }}
                  </div>
                </div>
                <div class="attendee-order-details-row fx-layout-row">
                  <div class="attendee-order-details-label">Email:</div>
                  <div class="attendee-order-details-value">
                    {{ attendee.email }}
                  </div>
                </div>
                <div class="attendee-order-details-row fx-layout-row">
                  <div class="attendee-order-details-label">Order Date:</div>
                  <div class="attendee-order-details-value">
                    {{ attendee.created_at | date: 'medium' }}
                  </div>
                </div>
                <div class="attendee-order-details-row fx-layout-row">
                  <div class="attendee-order-details-label">Confirmation code:</div>
                  <div class="attendee-order-details-value">
                    {{ attendee.confirmation_code }}
                  </div>
                </div>
              </div>
              <!--Order Summary-->
              <div class="event-billing-order-summary-container">
                <div class="order-summary-title">Order summary</div>
                <table class="order-summary-table" mat-table [dataSource]="attendee.packages">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Package</th>
                    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                  </ng-container>
                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef>Quantity</th>
                    <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
                  </ng-container>
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let element">
                      <span class="order-summary-line-item-free" *ngIf="element.price === 0">FREE</span>
                      <span class="order-summary-line-item-paid" *ngIf="element.price > 0">
                        {{ element.price | currency: attendee.currency_code }}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>Total</th>
                    <td mat-cell *matCellDef="let element">
                      <span class="order-summary-line-item-total-free" *ngIf="element.price === 0">FREE</span>
                      <span class="order-summary-line-item-total-paid" *ngIf="element.price > 0">
                        {{ element.price * element.quantity | currency: attendee.currency_code }}
                      </span>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </div>
              <div class="comments-container">
                <mat-form-field class="comments-field" appearance="outline">
                  <mat-label>Comments</mat-label>
                  <textarea matInput [(ngModel)]="attendee.comments"></textarea>
                </mat-form-field>
              </div>
              <mat-action-row>
                @if (!attendee.refunded) {
                  <div class="attendee-actions-row fx-layout-row fx-layout-align-end">
                    <button mat-button (click)="refund(attendee)">Refund</button>
                  </div>
                }
                <span class="fx-flex"></span>
                <button mat-button (click)="save(attendee)">Update Comment</button>
              </mat-action-row>
            </mat-expansion-panel>
          }
        </mat-accordion>
      }
    }
  </ng-container>
  <ng-container ih-footer>
    <button mat-button (click)="cancel()">Close</button>
    <span class="fx-flex"></span>
    <a mat-button href="/api/events/{{ eventId }}/attendees/export?tz={{ browserTimezone }}" target="_blank">Export</a>
  </ng-container>
</ih-basic-dialog>

<section class="wrapper site-min-height channel-add-people"></section>
