<ih-basic-dialog [scrollable]="true">
  <ng-container ih-header>Member views</ng-container>
  <div ih-content class="fx-layout-column">
    <mat-form-field>
      <input matInput type="search" [formControl]="userQuery" placeholder="Find member by name or email..." />
    </mat-form-field>
    <div class="mat-padding fx-layout-column">
      <ng-container *ngIf="{ userView: userViews$ | async, loading: loading$ | async } as data">
        <table
          mat-table
          [dataSource]="data.userView | filterUserViews: userQuery.value! : predicate"
          matSort
          (matSortChange)="changePredicate($event)"
        >
          <!-- User Column -->
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Member</th>
            <td mat-cell *matCellDef="let view" class="fx-layout-row fx-layout-align-start-center">
              <ih-image
                class="pull-left img-circle img-responsive"
                style="width: 40px; height: 40px; margin-right: 10px"
                [imageInfo]="view.user.image"
                *ngIf="view.user"
                alt="member pic"
              ></ih-image>
              <div class="user-info" *ngIf="view.user">
                <div>
                  <strong>
                    {{
                      !view.user.firstName && !view.user.lastName
                        ? 'N/A'
                        : (!view.user.firstName ? '' : view.user.firstName + ' ') + view.user.lastName
                    }}
                  </strong>
                </div>
                <div>{{ view.user.email }}</div>
              </div>
              <img
                class="pull-left img-circle img-responsive"
                style="width: 40px; height: 40px; margin-right: 10px"
                src="https://www.gravatar.com/avatar?s=40&d=mp"
                *ngIf="!view.user"
                alt="member pic"
              />
              <div *ngIf="!view.user"><strong>Anonymous member</strong></div>
              <div *ngIf="!view.user">N/A</div>
            </td>
          </ng-container>

          <!-- Count Column -->
          <ng-container matColumnDef="count">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by views">Views</th>
            <td mat-cell *matCellDef="let view">{{ view.count | number }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
        </table>
        <div *ngIf="data.loading" class="fx-layout-row fx-layout-align-center">
          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div *ngIf="data.userView?.length == 0 && !userQuery.value && !data.loading">There are currently no views</div>
        <div *ngIf="data.userView?.length == 0 && userQuery.value && !data.loading">
          Sorry, couldn't find anything to match that
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container ih-footer>
    <button mat-button (click)="cancel()">Close</button>
  </ng-container>
</ih-basic-dialog>
